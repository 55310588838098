import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import DemoBanner from '../components/DemoBanner';
import LandingPageHero from '../components/LandingPageHero';
import LandingPageDetail from '../components/LandingPageDetail'
import ResourcesSection from '../components/ResourcesSection';
import SiteMeta from '../components/SiteMeta';
import EmailBanner from '../components/EmailBanner';

const LandingPage = ({ data, pageContext }) => {

  const {
    contentfulLandingPages: {
      metaTitle,
      metaDescription: {
        metaDescription
      },
      title,
      cardImage,
      author,
      sidebarRelatedResource,
      relatedResources,
      form
    },
    contentfulLandingDetailPage: {
      demoCallout
    }, 
    contentfulHomepage: {
      mailingListCalloutText,
      emailForm,
    }

  } = data;

  const heroData = {
    title: title,
    subtitle: author,
    cardImage: cardImage
  }

  const emailBannerData = {
    title: mailingListCalloutText,
    form: emailForm,
  };


  return (
    <Layout>
      <SiteMeta description={metaDescription} title={metaTitle} />
      <LandingPageHero {...heroData} variant={'centered-left-aligned'} />
      <LandingPageDetail {...data.contentfulLandingPages} form={form} sidebarResource={sidebarRelatedResource} />
      <DemoBanner theme="dark" {...demoCallout} />
      <ResourcesSection title={'Related resources'} subtitle={null} featuredResources={relatedResources} />
      <EmailBanner theme="yellow" {...emailBannerData} />
    </Layout>
  )
}

export default LandingPage;

export const query = graphql`
  query LandingPageQuery($contentful_id : String!) {
    contentfulLandingPages(contentful_id : { eq: $contentful_id  }){
      ...LandingDetail
      form {
        ...Form
      }
    }
    contentfulLandingDetailPage {
      demoCallout {
        ...Navigator
      }
      form {
        ...Form
      }
    }
    contentfulHomepage {
      mailingListCalloutText
      emailForm {
        ...Form
      }
    }
  }
`;