import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import RichText from '../RichText';
import BackgroundImage from 'gatsby-background-image';
import { inViewTriggerThresholds } from '../../animationGlobals';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const LandingPageHero = ({ title, subtitle, variant, cardImage }) => {
  const sectionRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    const sectionEl = sectionRef.current;
    const contentEl = contentRef.current;

    const toCommon = {
      duration: 2,
      ease: 'power2.out',
    };

    const delayCommon = `<${toCommon.duration * 0.3}`;

    const InFromBottom = [
      {
        autoAlpha: 0,
        y: '25%',
      },
      {
        autoAlpha: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const animation = gsap.timeline({}).fromTo(contentEl, ...InFromBottom);

    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      ...inViewTriggerThresholds,
      animation: animation,
    });

    return () => {
      animation.progress(1);
      animation.kill();
      trigger.kill();
    };
  }, []);

  return (
    <div ref={sectionRef} className={`lp-hero lp-hero__header lp-hero--${variant}`}>
      <BackgroundImage
        tag={'div'}
        fluid={cardImage?.fluid}
        className="lp-hero__header-inner"
      />
      <div className={'lp-hero__inner'}>
        <div ref={contentRef} className="lp-hero__content">
          <h1 className="lp-hero__title">{title}</h1>
          {subtitle &&
            (subtitle?.json ? (
              <RichText
                className="lp-hero__subtitle"
                source={subtitle.json}
              />
            ) : (
              <p className="lp-hero__subtitle">{subtitle}</p>
            ))}
        </div>{' '}
      </div>
    </div>
  );
};

LandingPageHero.propTypes = {
  variant: PropTypes.oneOf(['left-aligned', 'centered', 'centered-left-aligned']),
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cardImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LandingPageHero.defaultProps = {
  variant: 'centered-left-aligned',
  title: 'Hero title',
  subtitle: null,
  cardImage: null,
};

export default LandingPageHero;
